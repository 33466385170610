import React from "react";
import "./About.css";
import PageHeading from "../../components/client/common/widgets/PageHeading/PageHeading";
import { Helmet } from "react-helmet";

const About = () => (
  <div className="page-section page-margin page-about">
    <Helmet>
      <title>About Us - EAMSQUARE Pvt Ltd</title>
      <meta
        name="description"
        content="Learn more about EAMSQUARE Pvt Ltd and our mission."
      />
    </Helmet>
    <PageHeading title="About EAMSQUARE Pvt Ltd" highlight="EAMSQUARE" />
    <p>
      Founded in 2018 in Cochin, Kerala, <span></span>Eamsquare Pvt Ltd was
      built with a vision to provide top-tier construction solutions that
      combine innovation, quality, and expertise. Our services include
      architectural drawing, structural design, soil investigation, landscaping,
      and a wide range of engineering and contracting services.
    </p>
    <p>
      In 2021, we transitioned into a private limited company, enhancing our
      capabilities and expanding our reach in the construction industry. This
      shift empowered us to offer more comprehensive and efficient solutions,
      enabling us to handle projects of varying scales and complexities with
      precision and professionalism.
    </p>
    <p>
      In 2024, we established our second branch in Ettumanoor, Kottayam, to
      cater to a growing client base and deliver our services to a broader
      region. This expansion reflects our commitment to growth, excellence, and
      meeting the evolving needs of our clients.
    </p>
    <p>
      Our team of experienced engineers, architects, and contractors is
      dedicated to delivering results that exceed expectations. Whether it's
      designing innovative structures, investigating soil properties for
      construction, or creating stunning landscapes, we approach every project
      with meticulous attention to detail and a client-first mindset.
    </p>
    <p>
      At Eamsqaure, we believe that successful construction begins with strong
      foundations — both literally and figuratively. By combining
      state-of-the-art technology with expert craftsmanship, we ensure that each
      project is not only structurally sound but also aesthetically appealing
      and environmentally sustainable.
    </p>
    <p>
      As we continue to grow, our mission remains clear: to be the preferred
      partner for construction solutions, delivering projects that stand the
      test of time while building trusted relationships with our clients.
    </p>
  </div>
);

export default About;
