// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/client/common/Header/Header";
import Footer from "./components/client/common/Footer/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Projects from "./pages/Projects/Projects";
import Contact from "./pages/Contact/Contact";
import JobVacancies from "./pages/JobVacancies/JobVacancies";
import ScrollToTop from "./components/client/common/widgets/ScrollTop/ScrollTop";
import ContactIcon from "./components/client/common/widgets/ContactIcon/ContactIcon";
import Preloader from "./components/client/common/widgets/Preloader/Preloader"; // Import Preloader component
import "./App.css";

const App = () => {
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    // Simulate loading (or you could use an actual data fetch here)
    setTimeout(() => {
      setLoading(false); // Stop loading after 2 seconds
    }, 2000);
  }, []);

  if (loading) {
    // Show preloader while loading
    return <Preloader />;
  }

  return (
    <Router>
      <ScrollToTop /> {/* Include ScrollToTop component */}
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/careers" element={<JobVacancies />} />
        </Routes>
      </main>
      <Footer />
      <ContactIcon /> {/* Add the ContactIcon component */}
    </Router>
  );
};

export default App;
