import React from "react";
import "./Preloader.css";

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="preloader-welcome-container">
        {/* Welcome text */}
        <div className="preloader-welcome">
          <span className="eam">
            <span>E</span>
            <span>A</span>
            <span>M</span>
          </span>
          &nbsp;
          {/* SQUARE with orange color and letter jump animation */}
          <span className="square">
            <span>S</span>
            <span>Q</span>
            <span>U</span>
            <span>A</span>
            <span>R</span>
            <span>E</span>
          </span>
        </div>
      </div>
      {/* Circular loader below the animated text */}
      <div className="loader"></div>
    </div>
  );
};

export default Preloader;
