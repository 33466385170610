// src/pages/Home/Home.js
import React from "react";
import Hero from "../../components/client/common/Hero/Hero";
import About from "../../components/client/other/About/About";
import Services from "../../components/client/other/Services/Services";
import Projects from "../../components/client/other/Projects/Projects";
import Testimonial from "../../components/client/other/Testimonial/Testimonial";
import Contact from "../../components/client/other/Contact/Contact";
import "./Home.css";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div className="home">
      <Helmet>
        <title>Home - EAMSQUARE Pvt Ltd</title>
        <meta
          name="description"
          content="Welcome to EAMSQUARE. We provide construction solutions and civil engineering services."
        />
        <meta
          name="keywords"
          content="construction, civil engineering, soil investigation,construction solutions, builders in Kerala, Kottayam, Kochi, Ernakulam, Kannur, Kollam, Trivandrum, Thiruvananthapuram, Thrissur, Trichur, Malappuram, Kozhikode, Calicut, Kannur, Canannore, Kasargod, Idukki, Pathanamthitta, Thiruvalla, Alappuzha, Alleppey, Palakkad, Wayanad, soil inspection, civil design, AutoCAD, architecture"
        />
      </Helmet>
      <Hero />
      <About />
      <Services />
      {/* <Projects /> */}
      {/* <Testimonial /> */}
      {/* <Contact /> */}
    </div>
  );
};

export default Home;
