import React from "react";
import "./TopBanner.css"; // Make sure you create and link this stylesheet
import {
  FaClock,
  FaEnvelope,
  FaGlobe,
  FaMobileAlt,
  FaPhoneAlt,
} from "react-icons/fa";

const TopBanner = () => {
  return (
    <div className="top-banner">
      <div className="banner-item timing">
        <div className="link">
          <FaClock />
          <span className="link-text">Mon - Fri: 9 AM - 5 PM</span>
        </div>
      </div>
      <div className="banner-item numbers">
        <div className="numbers-container">
          <div className="link">
            <FaPhoneAlt />
            <span
              className="link-text"
              onClick={() => (window.location.href = "tel:+914812962568")}
            >
              +91 481-2962568
            </span>
          </div>
          <div className="link">
            <FaMobileAlt />
            <span
              className="link-text"
              onClick={() => (window.location.href = "tel:+914812962568")}
            >
              +91 481-2962568
            </span>
          </div>
          <div className="link">
            <FaMobileAlt />
            <span
              className="link-text"
              onClick={() => (window.location.href = "tel:+914812962568")}
            >
              +91 481-2962568
            </span>
          </div>
        </div>
      </div>
      <div className="banner-item email-web">
        <div className="link">
          <FaEnvelope />
          <span
            className="link-text"
            onClick={() => (window.location.href = "mailto:info@eamsquare.com")}
          >
            info@eamsquare.com
          </span>
        </div>
        <div className="link">
          <FaGlobe />
          <span
            className="link-text"
            onClick={() => (window.location.href = "https://eamsquare.in/")}
          >
            www.eamsquare.com
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
